import React from "react";
import FooterMenuColumn from './footer-menu-column';

const FooterMenu = () => {
    const products_menu =
    [
        {
            'text': 'Business up',
            'url': '/businessup'
        },
        {
            'text': 'Crowdfunding',
            'url': '/products',
            'params': {'activeTab': 'crowdfunding'}
        },
        {
            'text': 'Goldsmith® Toolkit',
            'url': '/products',
            'params': {'activeTab': 'toolkit'}
        },
        // services
        {
            'text': 'Training',
            'url': '/services',
        },
        {
            'text': 'Mentoring',
            'url': '/services'
        },
        {
            'text': 'Consulting',
            'url': '/services'
        },
    ];

    const investmentMenu =
    [
        {
            'text': 'Adventurees',
            'externalUrl': 'https://www.adventurees.com' 
        },
        /*{
            'text': 'Investment forum',
            'externalUrl': 'https://fintech.adventurees.com/static/investment.html'
        },*/
        {
            'text': 'Investment clubs',
            'url': '/investment#investment-clubs',
        }
    ];

    const informationMenu =
    [
        // {
        //     'text': 'Coporations',
        //     'url': '/clients'
        // },
        // {
        //     'text': 'Incubators, accelerators and consultors',
        //     'url': '/clients'
        // },
        // {
        //     'text': 'Universities and Business Schools',
        //     'url': '/clients'
        // },
        // {
        //     'text': 'Networks of Business Angels, Funds and VCs',
        //     'url': '/clients'
        // },
        // {
        //     'text': 'NGOs, Foundations and Assosiations',
        //     'url': '/clients'
        // },
        // {
        //     'text': 'Public Administrations',
        //     'url': '/clients'
        // }
        {
            'text': 'Latest news',
            'url': '/news'
        },
        {
            'text': 'Contact',
            'url': '/contact'
        },
        {
            'text': 'Our team',
            'url': '/team'
        }
    ];

    const legalMenu =
    [
        {
            'text': 'Legal Advice',
            'url': '/legal-advice'
        },
        {
            'text': 'Privacy Policy',
            'url': '/privacy-policy'
        },
        {
            'text': 'Cookies Policy',
            'url': '/cookies-policy'
        },
        {
            // 'text': 'Ordinary General Comitee',
            'text': 'Ordinary and Extraordinary General Comitee',
            'externalUrl': 'https://www.adventurees-alliance.com/files/20220614_convocatoria_JGO_Adventurees_Alliance_SL.pdf'
        },
        {
            'text': 'Transparency',
            'url': '/transparency'
        }
    ];

    return <div className="footer-menu">
        <FooterMenuColumn title="Products & Services" menuItems={products_menu}/>
        {/*<FooterMenuColumn title="Services" menuItems={servicesMenu}/>*/}
        <FooterMenuColumn title="Investment" menuItems={investmentMenu}/>
        <FooterMenuColumn title="Information" menuItems={informationMenu}/>
        <FooterMenuColumn title="Legal" menuItems={legalMenu}/>
    </div>
}

export default FooterMenu;