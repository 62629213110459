import React from "react";

import SocialTopNetwork from '../header/social-top-network';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { injectIntl } from "gatsby-plugin-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons'


const SocialsFooter = (props) => (
    <div className="follow">
        {/*
        <div className="mailchimp-subscription">
            <div style={{backgroundColor: '#222', clear: 'left', font: '14px Helvetica, Arial, sans-serif', width: '100%'}}>
                <form action="https://adventurees.us2.list-manage.com/subscribe/post?u=144dfabb1f57fda6c7c2df7f5&amp;id=eb7ca9c7d9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                    <div className="field">
                        <h3 htmlFor="mce-EMAIL">
                            {props.intl.formatMessage({id: "Subscribe to our newsletter"})}
                        </h3>
                        <input style={{width: "250px"}} type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder={props.intl.formatMessage({id: "Email address"})} required />
                        <div style={{display: "none"}} aria-hidden="true">
                            <input type="text" name="b_144dfabb1f57fda6c7c2df7f5_eb7ca9c7d9" tabIndex="-1" defaultValue="" />
                        </div>
                        <div className ="form-trigger">
                            <button type="submit" name="subscribe" id="mc-embedded-subscribe">
                                <FontAwesomeIcon icon={faEnvelopeOpen} />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        */}
        <h3>Síguenos</h3>
        <ul className="socials-footer">
            <li className="facebook"><ul><SocialTopNetwork icon={faFacebookF} link="https://www.facebook.com/AdventureesGO" network="facebook" /></ul></li>
            <li className="twitter"><ul><SocialTopNetwork icon={faTwitter} link="https://twitter.com/AdventureesGO" network="twitter" /></ul></li>
            <li className="linkedin"><ul><SocialTopNetwork icon={faLinkedinIn} link="https://www.linkedin.com/company/adventurees/" network="linkedin" /></ul></li>
            <li className="instagram"><ul><SocialTopNetwork icon={faInstagram} link="https://www.instagram.com/adventurees.go/" network="instagram" /></ul></li>
        </ul>
    </div>
)

export default injectIntl(SocialsFooter);