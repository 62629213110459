import React from "react"

//import ContactFooter from './layout/footer/contact-footer';
import FooterMenu from './layout/footer/footer-menu';
import SocialsFooter from './layout/footer/socialsFooterNew';
import "../styles/layout/footer.less";

const Footer = ({intl}) => {
	return <footer className="site-footer">
		<div className="container">
			<div className="row">
				<FooterMenu />
				<SocialsFooter />
				{/* <ContactFooter /> */ }
				
				{ /* <BrandingFooter /> */ }
			</div>
		</div>
	</footer>
}

export default Footer;