import React from "react";
import PropTypes from "prop-types";

import AdvTitle from './advTitle';

const Section = (props) => (
  <section className={"section "+props.className} id={props.id}>
      <div className="container">
        {(props.title || props.subtitle) && <AdvTitle title={props.title} subtitle={props.subtitle} />}
        {props.children}
      </div>
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
}

export default Section;