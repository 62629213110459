import React from "react";
import { Link, injectIntl } from 'gatsby-plugin-intl';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const FooterMenuColumn = (props) => {
    return <div className="footer-menu-item">
        <h3>{props.intl.formatMessage({id: props.title})}</h3>
        <ul>
            {props.menuItems.map((menu, index) => {
                return <li key={index}>
                    {menu.url &&
                        <Link 
                            to={menu.url}
                            params={menu.params}
                        >
                            {props.intl.formatMessage({id: menu.text})}
                        </Link>
                    }
                    {menu.externalUrl &&
                        <>
                            <a href={menu.externalUrl} target="_blank" rel="noopener noreferrer">{props.intl.formatMessage({id: menu.text})}</a>
                            &nbsp;
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </>
                    }
                </li>
            })}
        </ul>
        
    </div>
}

export default injectIntl(FooterMenuColumn);