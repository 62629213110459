import React from "react";
import PropTypes from 'prop-types';

import "../styles/adv-page-title.less";

const AdvTitle = (props) => (
  <div className="adv-page-title">
      <h2 className="title">{props.title}</h2>
      {props.subtitle && <p className="subtitle">{props.subtitle}</p>}
  </div>
)

AdvTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
}

export default AdvTitle;
