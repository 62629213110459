import React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SocialTopNetwork = ({network, link, icon}) => (
    <li className={network}>
        <a aria-label={network} rel="noopener noreferrer" target="_blank" href={link}>
            <FontAwesomeIcon icon={icon} />
        </a>
    </li>
)

export default SocialTopNetwork;
